/* These styles are applied within the content of a ticket */
@use "typography" as *;
@use "mixins" as *;

/* Ticket classes comes with escaped symbols when loading from a list */
.freshservice-custom-table--reference,
.\\\"freshservice-custom-table--reference\\\",
.freshservice-custom-table--data,
.\\\"freshservice-custom-table--data\\\" {
  width: 100%;

  th {
    text-align: left;
    border-bottom: 0.1rem solid var(--datatable__row-border-color);
    padding: 0.8rem 0.4rem;
    @include mq("sm") {
      padding: 1rem 0.4rem;
    }
    @include mq("md") {
      padding: 0.3rem 0.4rem;
    }
    @include mq("lg") {
      padding: 0.4rem 0.4rem;
    }
    @include mq("xl") {
      padding: 0.6rem 0.4rem;
    }
    @include mq("xl", "+") {
      padding: 0.8rem 0.4rem;
    }
    font-weight: $font-weight-body--medium;
  }
}

/*
 * Styling for tables dispalying firewall rule request details
 */
.request-information-table {
  border-color: var(--neutral);
  border-collapse: separate;
  border-spacing: 0rem;

  &__name {
    color: var(--neutral);
    min-width: 13rem;
  }

  td {
    border-top: solid 0.1rem var(--neutral);
    border-left: solid 0.1rem var(--neutral);
    padding: 1rem;
  }

  tbody:first-child tr:first-child td:first-child {
    border-radius: 0.5rem 0rem 0rem 0rem;
  }

  tbody:first-child tr:first-child td:last-child {
    border-radius: 0rem 0.5rem 0rem 0rem;
  }

  td:last-child {
    border-right: solid 0.1rem var(--neutral);
  }

  tr:last-child td:first-child {
    border-bottom: solid 0.1rem var(--neutral);
    border-radius: 0rem 0rem 0rem 0.5rem;
  }

  tr:last-child td:last-child {
    border-bottom: solid 0.1rem var(--neutral);
    border-radius: 0rem 0rem 0.5rem 0rem;
  }
}
