@use "mixins" as *;
@use "typography" as *;
@use "buttons";

// These styles can't live within shared/components/custom-toast as the content is projected
// Double classed for higher specificity
// Class names are set by the module and can't be changed
.toast-container.toast-container {
  .ngx-toastr {
    align-content: center;
    background-color: var(--toast--info-background);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 2rem 2rem 6rem;
    box-shadow: none;
  }

  .toast-icon {
    position: absolute;
    fill: var(--toast__icon-color);
    color: var(--toast__icon-color);
    top: 50%;
    margin-top: -1.6rem;
    left: 1.4rem;
  }

  .toast-info {
    background-color: var(--toast--info-background);
    background-image: none;
  }

  .toast-error {
    background-color: var(--toast--error-background);
    background-image: none;
  }

  .toast-success {
    background-color: var(--toast--success-background);
    background-image: none;
  }

  .toast-warning {
    background-color: var(--toast--warning-background);
    background-image: none;
  }

  .toast-title {
    font-family: var(--font-family-heading);
    font-size: $font-size--xl;
    font-weight: $font-weight-heading--medium;
    margin: 0 0 0.5rem;
  }

  .toast-message {
    @include demarginalise;
    color: var(--toast__text-color);

    &__button {
      @include buttons.generic-button;
      font-weight: $font-weight-body--semibold;
      border: 0.1rem solid var(--toast__text-color);

      &:hover {
        background-color: var(--toast__text-color);
        color: var(--toast--warning-background);
      }
    }
  }

  .toast-close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    text-decoration: none;
    height: 2rem;
    width: 2rem;
    display: block;
    padding: 0;
    text-shadow: none;
  }
}
