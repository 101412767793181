/**
 * MIXINS
 *
 * This file can be imported into *.component.scss files using @use 'mixins';
 *
 * Mixins:
 * - media query
 * - demarginalise (take off top margin on first child, bottom margin on last child)
 * - dimensions (give height and width to an element)
 * - circle (give same height and width and round corners)
 * - icon (set Google Icon Font and settings for icons)
 * - card (set styles [background/color/border/shadow] for cards
 *
 **/

@use "variables";
@use "sass:math";
@use "functions";
@use "typography";

/**
 * Remove top margin from first child and bottom margin from last child
 **/
@mixin demarginalise {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Set an element to be a fixed size
 **/
@mixin dimensions($width, $height: $width) {
  height: $height;
  width: $width;
}

/**
 * Set an element to be a fixed size circle
 **/
@mixin circle($diameter) {
  @include dimensions($diameter);
  border-radius: 50%;
}

/**
 * ICON
**/
$icon-sizes: (
  "xxs": 1.2rem,
  "xs": 1.6rem,
  "sm": 2rem,
  "md": 2.4rem,
  "lg": 3.2rem,
  "xl": 4.4rem,
);

@mixin icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  display: block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: currentColor;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
  // Icon sizes
  @each $name, $size in $icon-sizes {
    &.icon--#{$name} {
      @include dimensions($size);
      font-size: $size;
    }
  }
  // Status icons
  &.icon--primary {
    color: var(--icon__color--primary);
  }
  &.icon--secondary {
    color: var(--icon__color--secondary);
  }
  &.icon--success {
    color: var(--icon__color--success);
  }
  &.icon--warning {
    color: var(--icon__color--warning);
  }
  &.icon--error {
    color: var(--icon__color--error);
  }
  &.icon--neutral {
    color: var(--icon__color--neutral);
  }
  &.icon--default {
    color: var(--icon__color--default);
  }
}
@mixin icon--outlined {
  @include icon;
  font-family: "Material Icons Outlined";
}

/**
 * CARD
**/
@mixin card {
  @include demarginalise;
  background: var(--card__background);
  color: var(--card__color);
  border-radius: 0.8rem;
  border: 0.1rem solid var(--card__border);
  overflow-y: auto;
  :host-context(.has-visible-overflow) & {
    overflow-y: visible;
  }
}

/**
 * ABSOLUTELY POSITIONED
 */
@mixin abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/**
 * FIXED POSITIONED
 * e.g. modal window
 */
@mixin fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/**
 * SHINE
 * Use on skeleton screens to show loading status
**/
$base-color: rgb(255, 255, 255, 0);
$shine-color: rgba(255, 255, 255, 0.2);
@mixin shine {
  position: absolute;
  display: block;
  content: " ";
  background-image: linear-gradient(
    0deg,
    $base-color 0,
    $shine-color 50%,
    $base-color 100%
  );
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@mixin shineSquare {
  @include shine;
  transform: skewX(-20deg) translateX(0);
  animation-name: slideRight;
}

@mixin shineCircle {
  @include shine;
  animation-name: circle;
}

@keyframes slideRight {
  0% {
    left: -25%;
  }
  50% {
    left: 125%;
  }
  100% {
    left: 125%;
  }
}
@keyframes circle {
  0% {
    transform: rotate(90deg); // Start at the top
  }
  50% {
    transform: rotate(450deg);
  }
  100% {
    transform: rotate(450deg);
  }
}

/**
 * Media query mixin
 * Pass it a size ("xs", "sm", "md", "lg", "xl")
 * And scope ('~' [1], '+' [2], '-' [3])
 * Or another breakpoint [4] - only valid combos are
 * ['xs', 'md'], ['xs', 'lg'], ['xs', 'xl'], ['sm', 'lg'], ['sm', 'xl'], ['md', 'xl']
 * e.g. mq('sm', '+') - targets screen sizes bigger than small (>1025)
 *      mq('lg', '~') - targets only large screen sizes (1281 - 1440)
 *      mq('sm', 'xl') - targets screen sizes between small and x-large (1025 - 1920)
 **/
@mixin mq($size, $scope: "~") {
  $onepixel: 1px;
  $max: map-get(variables.$breakpoints, $size);
  $min: 0;

  // Min size is one pixel over next size down
  @if $size == "xs" {
    $min: 0;
  } @else if $size == "sm" {
    $min: map-get(variables.$breakpoints, "xs");
  } @else if $size == "md" {
    $min: map-get(variables.$breakpoints, "sm");
  } @else if $size == "lg" {
    $min: map-get(variables.$breakpoints, "md");
  } @else if $size == "xl" {
    $min: map-get(variables.$breakpoints, "lg");
  }

  @if $scope == "~" {
    // [1] This size only (default)
    @media only screen and (min-width: $min) and (max-width: ($max - $onepixel)) {
      @content;
    }
  } @else if $scope == "+" {
    // [2] Bigger than this size
    @media only screen and (min-width: $max) {
      @content;
    }
  } @else if $scope == "-" {
    // [3] This size and smaller
    @media only screen and (max-width: ($max - $onepixel)) {
      @content;
    }
  } @else {
    // [4] Scope is another breakpoint
    $min: $max;
    $max: map-get(variables.$breakpoints, $scope) - $onepixel;
    @media only screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}

// CSS ARROW PLEASE
// designed to work with `card` above
@mixin arrow($direction) {
  position: relative;
  background: var(--card__background);
  border: 0.1rem solid var(--card__border);

  &:after,
  &:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(12, 51, 67, 0);
    border-width: 10px;
  }
  &:before {
    border-color: rgba(23, 66, 83, 0);
    border-width: 11px;
  }

  @if ($direction == "top") {
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
    }
    &:after {
      border-bottom-color: var(--card__background);
      margin-left: -10px;
    }

    &:before {
      border-bottom-color: var(--card__border);
      margin-left: -11px;
    }
  } @else if $direction == "right" {
    &:after,
    &:before {
      left: 100%;
      top: 50%;
    }
    &:after {
      border-left-color: var(--card__background);
      margin-top: -10px;
    }

    &:before {
      border-left-color: var(--card__border);
      margin-top: -11px;
    }
  } @else if $direction == "bottom" {
    &:after,
    &:before {
      top: 100%;
      left: 50%;
    }
    &:after {
      border-top-color: var(--card__background);
      margin-left: -10px;
    }

    &:before {
      border-top-color: var(--card__border);
      margin-left: -11px;
    }
  } @else if $direction == "left" {
    &:after,
    &:before {
      right: 100%;
      top: 50%;
    }
    &:after {
      border-right-color: var(--card__background);
      margin-top: -10px;
    }

    &:before {
      border-right-color: var(--card__border);
      margin-top: -11px;
    }
  }
}

@mixin donut-skeleton($chartHeight, $chartThickness, $tracks) {
  $radialGradient: functions.radialGradient(
    $chartHeight,
    $chartThickness,
    $tracks
  );

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity variables.$base-transition;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  // Use a capital for CSS min() so Sass doesn't get confused
  width: Min(100%, $chartHeight);
  height: auto;
  aspect-ratio: 1;

  &::before {
    @include abs;
    z-index: 1;
    display: block;
    content: "";
    border-radius: 50%;
    background: $radialGradient;
    opacity: 0.2;
  }

  &.is-loading {
    $shineThickness: 4rem;
    &::after {
      @include shineCircle;
      z-index: 1;
      top: 50%;
      left: 0;
      margin-top: math.div($shineThickness, -2);
      // needs to stretch to middle to get transform origin right
      width: 50%;
      // then use clip-path to cut off at $chartThickness width
      clip-path: polygon(
        0 0,
        $chartThickness 0,
        $chartThickness $shineThickness,
        0 $shineThickness
      );
      height: $shineThickness;
      transform-origin: 100% 50%;
    }
  }
}

@mixin graph-skeleton($tracks: 5) {
  // How often the repeating gradient should repeat
  // (i.e. how many times in 100%)
  $repeatPercentage: math.div(100%, $tracks - 1);

  position: absolute;
  opacity: 0;
  transition: opacity variables.$base-transition;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  overflow: hidden;

  &::before {
    @include abs;
    content: "";
    display: block;
    border-left: 1px solid var(--chart__gridlines);
    border-bottom: 1px solid var(--chart__gridlines);
    background-image:
      // horizontal grid lines
      repeating-linear-gradient(
      0,
      var(--chart__gridlines),
      var(--chart__gridlines) 0.1rem,
      transparent 0.1rem,
      transparent $repeatPercentage
    );
  }
  .is-loading & {
    &::after {
      @include shineSquare;
      @include abs;
      left: -25%;
      height: 100%;
      width: 50px;
    }
  }

  .is-loading &,
  .has-error & {
    opacity: 1;
  }
}

// Default spacing and settings for pages
@mixin page() {
  display: grid;
  row-gap: 3rem;
  column-gap: 3rem;
  /* MD/LG/XL */
  @include mq("sm", "+") {
    row-gap: 3.5rem;
  }
}

// Legend positioning
@mixin legendPosition() {
  // Legend positions
  &.has-legend--top {
    flex-flow: column;
  }
  &.has-legend--right {
    flex-flow: column-reverse;
    @include mq("sm", "+") {
      flex-flow: row-reverse;
      align-items: center;
      .chart__legend {
        flex: 1 1 0;
      }
    }
  }
  &.has-legend--bottom {
    flex-flow: column-reverse;
  }
  &.has-legend--left {
    flex-flow: column;
    @include mq("sm", "+") {
      flex-flow: row;
      align-items: center;
      .chart__legend {
        flex: 1 1 0;
      }
    }
  }
}

@mixin displayNoneIfEmpty {
  &:empty {
    display: none;
  }
}

@mixin linkHoverAnimation {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: var(--active-tab-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:not(.tab--active):hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@mixin widgetTitle {
  @include displayNoneIfEmpty;
  margin: 0;
  flex: 1 1 auto;
  line-height: 4.4rem; // to match filter dropdowns
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
}

// Table cells <th>, >td> and footer area
@mixin paddedCell {
  padding: 0.8rem;
  @include mq("sm") {
    padding: 1.2rem;
  }
  @include mq("md", "+") {
    padding: 1.6rem;
  }
  .datatable--compact & {
    @include mq("sm", "+") {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

// Use triangle arrows as list items
@mixin arrow-list {
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 0.2rem;
    &:before {
      @include icon;
      color: var(--arrow-list__arrow);
      content: "arrow_right";
      float: left;
      font-size: 2rem;
      line-height: 1.2rem;
      text-indent: -0.8rem;
    }
  }
}

@mixin firewall-title {
  font-family: var(--font-family-body);
  font-size: typography.$font-size--lg;
  font-weight: typography.$font-weight-heading--medium;
}

@mixin firewall-subtitle {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family-body);
  font-size: 1.4rem;
  font-weight: 500;
}

@mixin firewall-text {
  color: var(--firewall-sub-text-color);
}
