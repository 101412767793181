/**
 * Utility classes
 */
@use "spacing";

/**
 * Show \n as newlines
 * e.g. used to show the support ticket descriptions
 **/
.formatted-text {
  white-space: pre-wrap;
}

/**
 * VERTICALLY CENTRED
 * Using flexbox
**/
.is-vertically-centred {
  display: flex;
  align-items: center;
}

/**
 * HORIZONTALLY CENTRED
**/
.is-centred {
  text-align: center;
}

/**
 * Colour text based on status
**/
.has-text-primary {
  color: var(--primary);
}
.has-text-secondary {
  color: var(--secondary);
}
.has-text-success {
  color: var(--success);
}
.has-text-warning {
  color: var(--warning);
}
.has-text-error {
  color: var(--error);
}
.has-text-neutral {
  color: var(--neutral);
}

/**
 * Font style overrides
**/
.has-body-font {
  font-family: var(--font-family-body);
}
.has-header-font {
  font-family: var(--font-family-heading);
}
