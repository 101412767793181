@use "typography" as *;

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  src: url("/assets/fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/assets/fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("/assets/fonts/MaterialIcons-Regular.woff") format("woff"),
    url("/assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  src: url("/assets/fonts/MaterialIconsOutlined-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons Outlined"), local("MaterialIconsOutlined-Regular"),
    url("/assets/fonts/MaterialIconsOutlined-Regular.woff2") format("woff2"),
    url("/assets/fonts/MaterialIconsOutlined-Regular.woff") format("woff"),
    url("/assets/fonts/MaterialIconsOutlined-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FinalSix";
  src: url("/assets/fonts/FinalSix Medium.ttf") format("truetype");
  font-weight: $font-weight-heading--medium;
  font-style: normal;
}

@font-face {
  font-family: "FinalSix";
  src: url("/assets/fonts/FinalSix Bold.ttf") format("truetype");
  font-weight: $font-weight-heading--bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Italic.eot");
  src: url("/assets/fonts/Montserrat-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Italic.woff2") format("woff2"),
    url("/assets/fonts/Montserrat-Italic.woff") format("woff"),
    url("/assets/fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Regular.eot");
  src: url("/assets/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("/assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-SemiBold.eot");
  src: url("/assets/fonts/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-SemiBoldItalic.eot");
  src: url("/assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/ontserrat-SemiBoldItalic.woff2") format("woff2"),
    url("/assets/fonts/Montserrat-SemiBoldItalic.woff") format("woff"),
    url("/assets/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
