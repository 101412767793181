body {
  /**
   * BRAND DEFINITIONS
   * These are just here for our benefit!
   * These variable names shouldn't be used in SCSS files
   **/

  // New colours
  --green-01: #02d2a0;
  --green-01-a60: #02d2a099;
  --green-01-a30: #02d2a04d;
  --green-01-a20: #02d2a033;
  --green-02: #049383;
  // --green-02-a60: #04938399;
  // --green-02-a30: #0493834d;
  --red-01: #ce5a5a;
  --red-01-a60: #ce5a5a99;
  --red-01-a30: #ce5a5a4d;
  --orange-01: #f98d51;
  --orange-01-a30: #f98d514d;
  // --blue-01: #c6dbef;
  // --blue-02: #9ecae1;
  --blue-03: #65a0d7;
  --blue-03-a60: #65a0d799;
  --blue-03-a30: #65a0d74d;
  --blue-03-a20: #65a0d733;
  --purple-01: #8282ab;
  --purple-01-a60: #8282ab99;
  --purple-01-a30: #8282ab4d;
  --purple-01-a20: #8282ab33;

  --black-01: #0d0d0f;
  --black-01-a60: #0d0d0f99;
  --black-01-a30: #0d0d0f4d;
  --black-02: #111115;
  --black-03: #16161c;
  --black-04: #191920;
  --black-05: #1e1e1e;

  --grey-01: #9595a7;
  --grey-01-a60: #9595a799;
  --grey-01-a30: #9595a74d;
  --grey-01-a20: #9595a733;
  --grey-015: #777787; // Used in the map component
  --grey-02: #595a67;
  --grey-02-a30: #595a674d;
  --grey-03: #343541;
  --grey-04: #22222b;

  --white-01: #ffffff;
  --teal-01: rgb(42, 198, 208);

  /**
    * THEME DEFINITIONS
    * These are the variables that can be updated on a per-theme basis
    * These variable names shouldn't be used in SCSS files
    **/

  --font-family-heading: "FinalSix", "Montserrat", Verdana, sans-serif;
  --font-family-body: "Montserrat", Verdana, sans-serif;

  // Basic colours (descriptive names)
  --highlight-1: var(--green-01);
  --highlight-1-a60: var(--green-01-a60);
  --highlight-1-a30: var(--green-01-a30);
  --highlight-1-a20: var(--green-01-a20);

  --highlight-2: var(--orange-01);
  --highlight-2-a30: var(--orange-01-a30);

  --highlight-3: var(--blue-03);
  --highlight-3-a60: var(--blue-03-a60);
  --highlight-3-a30: var(--blue-03-a30);
  --highlight-3-a20: var(--blue-03-a20);

  --highlight-4: var(--red-01);
  --highlight-4-a30: var(--red-01-a30);

  --highlight-5: var(--grey-01);
  --highlight-5-a60: var(--grey-01-a60);
  --highlight-5-a30: var(--grey-01-a30);
  --highlight-5-a20: var(--grey-01-a20);

  // --highlight-6: var(--green-01);
  // --highlight-6-a60: var(--green-01-a60);
  // --highlight-6-a30: var(--green-01-a30);
  // --highlight-6-a20: var(--green-01-a20);

  --highlight-7: var(--black-01);
  --highlight-8: var(--teal-01);

  --highlight-9: var(--green-02);

  // UI colours
  --ui-1: var(--black-01);
  --ui-1-a60: var(--black-01-a60);
  --ui-1-a30: var(--black-01-a30);
  --ui-2: var(--black-02);
  --ui-3: var(--black-03);
  --ui-4: var(--black-04);
  --ui-5: var(--black-05);
  --ui-6: var(--grey-04);
  --ui-7: var(--grey-03);
  --ui-8: var(--grey-02);
  --ui-8-a30: var(--grey-02-a30);
  --ui-9: var(--grey-01);
  --ui-9-a60: var(--grey-01-a60);
  --ui-9-a30: var(--grey-01-a30);
  --ui-9-a20: var(--grey-01-a20);
  --ui-0: var(--white-01);
}
