@use "typography" as *;

html,
body {
  width: 100%;
  min-height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-family-body);
  font-size: $font-size--xs;
  text-transform: none;
  font-weight: $font-weight-body--medium;
  background-color: var(--container__background);
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: underline; // should always show link underlines
  text-decoration-color: inherit;
  text-decoration-skip-ink: auto;

  &.pointer {
    cursor: pointer;
  }

  &.secondary-link {
    font-family: var(--font-family-body);
    font-size: $font-size--xs;
    text-transform: none;
    font-weight: $font-weight-body--medium;
    color: var(--highlight-1);
    text-decoration: none;
  }

  &.button {
    text-decoration: none;
  }
}

p {
  font-family: var(--font-family-body);
  font-size: $font-size--sm;
  font-weight: $font-weight-body--medium;
}

ul {
  font-family: var(--font-family-body);
  font-size: $font-size--sm;
  font-weight: $font-weight-body--medium;
}

label {
  font-family: var(--font-family-body);
  font-size: $font-size--sm;
  font-weight: $font-weight-body--medium;
}

input {
  font-family: var(--font-family-body);
  font-size: $font-size--sm;
  font-weight: $font-weight-body--medium;
}
