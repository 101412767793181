/**
 * VISIBILITY CLASSES
 * is-hidden, is-visible, plus size variants
**/

@use "mixins" as *;
@use "variables";

/*
 * is-visible/is-hidden classes
 * Used to show/hide elements
 * Each size has three distinct classes, eg:
 * .is-visible@md+ - visible at > medium
 * .is-visible@md  - visible at medium only
 * .is-visible@md- - visible at <= medium
 */
.is-hidden {
  display: none;
}

.is-visible {
  display: block;
}

// FIXME:
// I think these are a bad idea
// it's much clearer to just use the mq in scss with display:

@each $name, $size in variables.$breakpoints {
  @include mq(#{$name}, "-") {
    .is-visible\@#{$name}- {
      display: block;
    }
    .is-hidden\@#{$name}- {
      display: none;
    }
  }
  @include mq(#{$name}, "~") {
    .is-visible\@#{$name},
    .is-visible\@#{$name}\~ {
      display: block;
    }
    .is-hidden\@#{$name},
    .is-hidden\@#{$name}\~ {
      display: none;
    }
  }
  @include mq(#{$name}, "+") {
    .is-visible\@#{$name}\+ {
      display: block;
    }
    .is-hidden\@#{$name}\+ {
      display: none;
    }
  }
}

/**
 * Show \n as newlines
 * e.g. used to show the support ticket descriptions
 **/
.formatted-text {
  white-space: pre-wrap;
}
