@use "typography" as *;
@use "buttons";

div.md-drppicker {
  /* Prevent animation triggering on page load */
  &.preload {
    transition-duration: 0s !important;
  }

  margin-top: 0rem;
  background-color: var(--datepicker-cal__background);
  border: 0.2rem solid var(--datepicker-cal__border);
  border-radius: 0.8rem;
  box-shadow: -0.1rem -0.1rem 1rem 0.1rem var(--datepicker-cal__shadow);
  margin-top: 1rem;
  overflow: visible;

  // Don't want two calendars when we're choosing a date range
  .calendar.left ~ .calendar.right .calendar-table {
    display: none;
  }
  .calendar {
    float: none !important;
    margin: 4px !important;
  }
  .calendar.left .calendar-table {
    padding-right: 4px !important;
  }
  // Label the to and from times
  &.double .calendar {
    .calendar-time {
      margin-right: 3.6rem; // To centre label + select
      &::before {
        position: absolute;
        right: calc(100% + 10px);
        top: 50%;
        line-height: 2rem;
        margin-top: -1rem;
        font-weight: normal;
      }
    }
    &.left .calendar-time::before {
      content: "From";
    }
    &.right .calendar-time::before {
      content: "To";
    }
  }

  .calendar-table {
    background-color: var(--datepicker-cal__background);
    border: none;

    td.in-range,
    td.in-range:hover {
      background-color: var(--datepicker-cal__day__background--in-range);
      border-radius: 0.8rem;
      color: var(--datepicker-cal__day--in-range);
    }

    td.active,
    td.active:hover {
      background-color: var(--datepicker-cal__day__background--active);
      border-radius: 0.8rem;
      color: var(--datepicker-cal__day--active);
    }

    td.available:hover {
      background-color: var(--datepicker-cal__day__background--active);
      border-radius: 0.8rem;
      color: var(--datepicker-cal__day--active);
    }

    td.off,
    td.off.end-date,
    td.off.in-range,
    td.off.start-date {
      background-color: var(--datepicker-cal__background);
      color: var(--datepicker-cal__day--inactive);
    }

    td.disabled,
    option.disabled {
      background: var(--datepicker-cal__background--disabled);
      color: var(--datepicker-cal__color--disabled);
      text-decoration: none;
      border-radius: 0;
    }
  }

  .calendar-time {
    background: var(--datepicker-cal__background--disabled);
    width: 55%;
    margin: 0 auto;
    padding-left: 1rem;

    .select .select-item {
      color: var(--form-field__color);
      margin-right: 1rem;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'><path fill='%23FFFFFF' d='M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z'/></svg>")
        no-repeat right -0.5rem center/1.9rem;
      appearance: none;
      width: 3.6rem;
    }
  }

  th.available {
    -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMy43IDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMuNyA2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0zLjcsMC43TDEuNCwzbDIuMywyLjNMMyw2TDAsM2wzLTNMMy43LDAuN3oiLz4NCjwvZz4NCjwvc3ZnPg0K")
      no-repeat;
    mask: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMy43IDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMuNyA2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0zLjcsMC43TDEuNCwzbDIuMywyLjNMMyw2TDAsM2wzLTNMMy43LDAuN3oiLz4NCjwvZz4NCjwvc3ZnPg0K")
      no-repeat;
    background-color: var(--datepicker-cal__arrow);
    height: 1.2rem;

    &.next {
      transform: translate(-2.5rem, 0.6rem) rotate(180deg);
    }

    &.prev {
      transform: translate(2.5rem, 0.7rem);
    }

    &:hover {
      &.next {
        transform: translate(-2.5rem, 0.6rem) rotate(180deg);
      }
      background-color: var(--datepicker-cal__hover);
    }
  }

  .calendar {
    th,
    td {
      font-family: var(--font-family-body);
      font-size: 1.2rem;
      text-transform: none;
      font-weight: $font-weight-body--medium;
    }

    th {
      color: var(--datepicker-cal__weekday);
    }
  }

  &.shown {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  &.hidden {
    transform: translateY(-50px);
    opacity: 0;
    pointer-events: none;
    .calendar {
      display: block;
    }
  }

  .btn {
    font-family: var(--font-family-heading);
    font-size: $font-size--lg;
    text-transform: uppercase;
    font-weight: $font-weight-heading--bold;
    @include buttons.generic-button;
    display: inline;
    margin-right: 0.7rem;
    width: 100%;
    padding: 0.4rem 1.6rem;

    background-color: var(--button__background--primary);
    border-color: var(--button__border--primary);
    color: var(--button__color--primary);

    &:hover {
      background-color: var(--button__background-hover--primary);
      border-color: var(--button__border-hover--primary);
      color: var(--button__color-hover--primary);
    }
  }

  td.in-range {
    background-color: var(--datepicker-cal__background);
    color: inherit;
  }

  // Arrow
  &::after,
  &::before {
    position: absolute;
    content: " ";
    height: 0;
    width: 0;
    bottom: 100%;
    border: solid transparent;
    border-color: rgba(204, 204, 204, 0);
  }
  &::before {
    margin-left: -11px;
    border-width: 11px;
    border-bottom-color: var(--datepicker-cal__border);
  }
  &::after {
    border-width: 8px;
    border-bottom-color: var(--datepicker-cal__background);
  }

  &.drops-down-left {
    left: 2px !important;
    right: auto !important;
    &::before,
    &::after {
      left: 2rem;
    }
    &::after {
      margin-left: -8px;
    }
  }
  &.drops-down-right {
    left: auto !important;
    right: 2px !important;
    &::before,
    &::after {
      right: 2rem;
    }
    &::after {
      margin-right: 3px;
    }
  }
  &.drops-down-center {
    &::before,
    &::after {
      left: 50%;
    }
    &::after {
      margin-left: -8px;
    }
  }

  .buttons {
    margin: 0.6rem 0 0 0;
  }
}
