@use "mixins" as *;

/**
 * CSS which is shared between multiple components should go here
 */

// Remove to and bottom from popover content
// Has to be done outside of component Sass
.popover > * {
  @include demarginalise();
}
