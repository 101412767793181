@use "mixins" as *;
@use "typography" as *;

// Ensure table rows aren't shown when empty, loading or errored
.cdk-table {
  &.is-empty,
  &.is-loading,
  &.has-error {
    tbody tr {
      display: none;
    }
  }
}

.cdk-header-cell[sort-header] {
  position: relative;
  padding-right: 3.6rem;
  cursor: pointer;
  &::after {
    @include icon;
    @include dimensions(1.2rem);
    font-size: 1.2rem;
    content: "unfold_more";
    color: var(--datatable__sort-arrow);
    display: inline-block;
    margin-top: -0.6rem;
    margin-left: 0.6rem;
    position: absolute;
    top: 50%;
    right: 1.2rem;
  }

  &.is-sorted--asc::after {
    content: "expand_less";
  }

  &.is-sorted--desc::after {
    content: "expand_more";
  }
}

.cdk-header-cell,
.cdk-footer-cell {
  @include paddedCell;
  font-family: var(--font-family-heading);
  font-size: $font-size--sm;
  font-weight: $font-weight-body--medium;
  text-align: left;
  color: var(--datatable__header-color);
}

.cdk-cell {
  @include paddedCell;
  font-family: var(--font-family-default);
  font-size: $font-size--sm;
  font-weight: $font-weight-body--medium;
  color: var(--datatable__cell-color);

  // Add this class to the cell in the column definition to remove padding
  // e.g. cells with buttons
  &.cdk-cell--nopadding {
    padding: 0;
  }

  &--primary {
    color: var(--primary);
  }
  &--secondary {
    color: var(--secondary);
  }
  &--success {
    color: var(--success);
  }
  &--warning {
    color: var(--warning);
  }
  &--error {
    color: var(--error);
  }
  &--neutral {
    color: var(--neutral);
  }
}
