/**
 * SPACING MIXINS
 *
 * This file can be imported into *.component.scss files using @use 'spacing';
 *
 * Allows you to add default padding and margin to an element which will change with screen size.
 */

@use "mixins" as *;

// Default spacing (margin and padding)
$spacing: (
  "xs": 2rem,
  "sm": 2rem,
  "md": 2rem,
  "lg": 2rem,
  "xl": 2rem,
  "xl+": 2rem,
);

// Set padding (all sides)
@mixin padding {
  padding: 3rem;

  // @each $name, $size in $spacing {
  //   @include mq(#{$name}) {
  //     padding: $size;
  //   }
  // }
  // @include mq("xl", "+") {
  //   padding: map-get($spacing, "xl+");
  // }
}

// Set padding (top)
@mixin padding-top {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      padding-top: $size;
    }
  }
  @include mq("xl", "+") {
    padding-top: map-get($spacing, "xl+");
  }
}

// Set padding (right)
@mixin padding-right {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      padding-right: $size;
    }
  }
  @include mq("xl", "+") {
    padding-right: map-get($spacing, "xl+");
  }
}

// Set padding (bottom)
@mixin padding-bottom {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      padding-bottom: $size;
    }
  }
  @include mq("xl", "+") {
    padding-bottom: map-get($spacing, "xl+");
  }
}

// Set padding (left)
@mixin padding-left {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      padding-left: $size;
    }
  }
  @include mq("xl", "+") {
    padding-left: map-get($spacing, "xl+");
  }
}

// Set padding (horizontal)
@mixin padding-horizontal {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      padding-left: $size;
      padding-right: $size;
    }
  }
  @include mq("xl", "+") {
    padding-left: map-get($spacing, "xl+");
    padding-right: map-get($spacing, "xl+");
  }
}

// Set padding (vertical)
@mixin padding-vertical {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      padding-top: $size;
      padding-bottom: $size;
    }
  }
  @include mq("xl", "+") {
    padding-top: map-get($spacing, "xl+");
    padding-bottom: map-get($spacing, "xl+");
  }
}

// Set margin (all sides)
@mixin margin {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin: $size;
    }
  }
  @include mq("xl", "+") {
    margin: map-get($spacing, "xl+");
  }
}

// Set margin (top)
@mixin margin-top {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin-top: $size;
    }
  }
  @include mq("xl", "+") {
    margin-top: map-get($spacing, "xl+");
  }
}

// Set margin (right)
@mixin margin-right {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin-right: $size;
    }
  }
  @include mq("xl", "+") {
    margin-right: map-get($spacing, "xl+");
  }
}

// Set margin (bottom)
@mixin margin-bottom {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin-bottom: $size;
    }
  }
  @include mq("xl", "+") {
    margin-bottom: map-get($spacing, "xl+");
  }
}

// Set margin (left)
@mixin margin-left {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin-left: $size;
    }
  }
  @include mq("xl", "+") {
    margin-left: map-get($spacing, "xl+");
  }
}

// Set margin (horizontal)
@mixin margin-horizontal {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin-left: $size;
      margin-right: $size;
    }
  }
  @include mq("xl", "+") {
    margin-left: map-get($spacing, "xl+");
    margin-right: map-get($spacing, "xl+");
  }
}

// Set margin (vertical)
@mixin margin-vertical {
  @each $name, $size in $spacing {
    @include mq(#{$name}) {
      margin-top: $size;
      margin-bottom: $size;
    }
  }
  @include mq("xl", "+") {
    margin-top: map-get($spacing, "xl+");
    margin-bottom: map-get($spacing, "xl+");
  }
}
