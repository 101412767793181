/**
 * Generic button styles
**/
@mixin generic-button {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0.8rem 1.6rem;
  border-radius: var(--button-border-radius);
  min-height: 4rem;
  appearance: none;
  cursor: pointer;
  position: relative;
}
