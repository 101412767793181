/**
 * VARIABLES
 *
 * This file can be imported into *.component.scss files using @use 'variables';
 *
 * Sass variables should be used for values that are core to the app, and CSS variables should
 * be used for values that change with a theme
 *
 * code, pre {
 *   font-family: $monospace-font;
 *  }
 *
 **/

// Default transitions
$base-timing: 0.15s;
$base-easing: ease-in-out;
$base-transition: $base-timing $base-easing;
// Ease-in-out transition
$ease-in-out: 0.6s cubic-bezier(0.76, 0, 0.24, 1);

// Breakpoints
$breakpoints: (
  "xs": 425px,
  "sm": 768px,
  "md": 1024px,
  "lg": 1280px,
  "xl": 1920px,
);

// Cards
$card__shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.5);

// Animations
$animation--spin: 3.2s linear infinite animation--spin;
@keyframes animation--spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Icons
$icon-margin: 1rem;
$icon-size: 2.4rem;
