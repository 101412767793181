/**
 * TYPOGRAPHY
 *
 * This file can be imported into *.component.scss files using @use 'typography';
 *
 * One use case is for including common features into a component
 * such as font-size, padding or margin
 *
 * .page-title {
 *   font-size: $font-size-lg;
 *   font-weight: $font-weight-heading--bold;
 * }
 *
 **/

// Default fonts
$line-height--default: 1.4;

$font-size--xxs: 0.9rem;
$font-size--xs: 1.1rem;
$font-size--sm: 1.2rem;
$font-size--md: 1.4rem;
$font-size--lg: 1.6rem;
$font-size--xl: 1.8rem;
$font-size--xxl: 2.3rem;
$font-size--xxxl: 2.9rem;

$font-weight-body--default: 400;
$font-weight-body--medium: 500;
$font-weight-body--semibold: 600;
$font-weight-body--bold: 700;

$font-weight-heading--default: 400;
$font-weight-heading--medium: 500;
$font-weight-heading--bold: 600;
