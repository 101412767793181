@use "mixins" as *;
@use "typography" as *;

.file-picker {
  position: relative;
  &__dropzone {
    border: 2px dashed var(--card__border);
    border-radius: 0.4rem;
    text-align: center;
    padding: 2rem;
    &-text {
      font-family: var(--font-family-body);
      font-size: $font-size--sm;
      text-transform: none;
      font-weight: $font-weight-body--medium;
      margin: 0 0 0.8rem;
    }
    &-button {
      display: flex !important; // overrides block display in component styles
      justify-content: center;
    }
  }
  &__file {
    height: 8rem;
    display: flex;
    padding: 0 2rem;
    flex-direction: row;
    align-items: center;
    background: var(--card__background);
    border: 0.2rem dashed var(--card__border);
    border-radius: 0.6rem;
    margin-top: 2rem;
    position: relative;
    &-icon {
      @include dimensions(3rem);
      margin-right: 2rem;
    }
    &-details {
      margin: 0;
      flex-grow: 1;
    }
    &-name {
      font-family: var(--font-family-body);
      font-size: $font-size--lg;
      text-transform: none;
      font-weight: $font-weight-body--default;
    }
    &-size {
      color: var(--card-color);
    }
    &-name,
    &-size {
      margin: 0;
    }
    &-progress {
      border-radius: 0.6rem;
      position: absolute;
      height: 100%;
      width: 0;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
    }
    &-progress-text {
      text-align: right;
      flex-basis: auto;
      margin-right: 2rem;
    }
    &-status {
      flex-basis: 2rem;
    }
  }
  &__status {
    &-icon {
      @include dimensions(2rem);
      &--error {
        color: var(--error);
      }
      &--success {
        color: var(--success);
      }
      &--remove {
        cursor: pointer;
      }
    }
  }

  &__error-message {
    color: var(--error);
    font-weight: $font-weight-body--semibold;
  }
}
