/**
 * VARIABLE DEFINITIONS
 * These variables are used in the Angular component's SCSS.
 * They should be derived from the Theme Definitions in the theme's SCSS file.
 **/

body {
  // Information colours
  // Used in toasts, up/down arrows, stats etc
  --success: var(--highlight-1);
  --error: var(--highlight-4);
  --warning: var(--highlight-2);
  --info: var(--highlight-5);
  --neutral: var(--ui-9);
  --primary: var(--ui-0);
  --secondary: var(--highlight-3);

  // Container
  --container__background: var(--ui-1);
  --container__color: var(--ui-0);

  // Navigation
  --nav-primary__selected: var(--ui-4);
  --nav-primary__text: var(--ui-9);
  --nav-primary__icon: var(--ui-8);
  --nav-primary--hover: var(--ui-4);
  --nav-primary--active: var(--ui-4);
  --nav-primary__sub-bg: var(--ui-4);
  --nav-primary__sub-bg--hover: var(--ui-6);
  --nav-primary__sub-text: var(--ui-0);
  --nav-primary__bg: var(--highlight-7);
  --nav-primary-text--active: var(--ui-0);
  --nav-primary__account-name: var(--highlight-5);

  // Scrollbar
  --scrollbar-track: var(--ui-6);
  --scrollbar-thumb: var(--ui-7);

  // Header
  --top-banner__background: var(--ui-2);
  --top-banner-account-color: var(--highlight-5);

  // Cards
  --card__background: var(--ui-4);
  --card__border: var(--ui-6);
  --card__color: var(--ui-0);

  // Buttons
  --button-border-radius: 0.8rem;
  /// Default
  --button__background--default: var(--ui-3);
  --button__border--default: var(--ui-6);
  --button__color--default: var(--ui-9);
  /// Default - hover
  --button__background-hover--default: var(--ui-4);
  --button__border-hover--default: var(--highlight-1);
  --button__color-hover--default: var(--highlight-1);
  /// Default - disabled
  --button__background-disabled--default: var(--ui-1);
  --button__border-disabled--default: var(
    --button__background-disabled--default
  );
  --button__color-disabled--default: var(--ui-9);

  /// Primary
  --button__background--primary: var(--highlight-1);
  --button__border--primary: var(--button__background--primary);
  --button__color--primary: var(--ui-1);
  /// Primary - hover
  --button__background-hover--primary: var(--highlight-1-a60);
  --button__border-hover--primary: var(--button__background-hover--primary);
  --button__color-hover--primary: var(--ui-1);
  /// Primary - disabled
  --button__background-disabled--primary: var(--ui-3);
  --button__color-disabled--primary: var(--ui-8);
  --button__border-disabled--primary: var(--ui-6);

  /// Secondary
  --button__background--secondary: transparent;
  --button__border--secondary: var(--ui-0);
  --button__color--secondary: var(--ui-0);
  /// Secondary - hover
  --button__background-hover--secondary: var(--ui-4);
  --button__border-hover--secondary: none;
  --button__color-hover--secondary: var(--highlight-1);
  /// Secondary - disabled
  --button__background-disabled--secondary: var(--highlight-5-a60);
  --button__border-disabled--secondary: var(--ui-0);
  --button__color-disabled--secondary: var(--ui-0);

  /// Delete
  --button__background--delete: var(--highlight-4);
  --button__border--delete: var(--highlight-4);
  --button__color--delete: var(--ui-0);
  /// Delete - hover
  --button__background-hover--delete: var(
    --highlight-4-a30
  ); // TODO hover colours on delete button
  --button__border-hover--delete: var(--button__background-hover--delete);
  --button__color-hover--delete: var(--ui-0);
  /// Delete - disabled
  --button__background-disabled--delete: var(
    --highlight-4-a30
  ); // TODO disabled colours on delete button
  --button__border-disabled--delete: var(--button__background-disabled--delete);
  --button__color-disabled--delete: var(--ui-0);

  /// Refresh
  --button__border--refresh: var(--ui-8);
  --button__color--refresh: var(--ui-8);

  // Page title
  --page-title__icon: var(--highlight-5);

  // Icons and icon tiles
  --icon__background--primary: var(--highlight-5-a30);
  --icon__color--primary: var(--highlight-5);
  --icon__background--secondary: var(--highlight-3-a30);
  --icon__color--secondary: var(--ui-9);
  --icon__background--success: var(--highlight-1-a30);
  --icon__color--success: var(--highlight-1);
  --icon__background--warning: var(--highlight-2-a30);
  --icon__color--warning: var(--highlight-2);
  --icon__background--error: var(--highlight-4-a30);
  --icon__color--error: var(--highlight-4);
  --icon__background--neutral: var(--ui-8-a30);
  --icon__color--neutral: var(--ui-8);
  --icon__background--default: var(--ui-9-a30);
  --icon__color--default: var(--ui-0);

  // Pills
  --pill__background--primary: var(--highlight-5-a30);
  --pill__color--primary: var(--highlight-5);
  --pill__background--secondary: var(--highlight-3-a30);
  --pill__color--secondary: var(--highlight-3);
  --pill__background--success: var(--highlight-1-a30);
  --pill__color--success: var(--highlight-1);
  --pill__background--warning: var(--highlight-2-a30);
  --pill__color--warning: var(--highlight-2);
  --pill__background--error: var(--highlight-4-a30);
  --pill__color--error: var(--highlight-4);
  --pill__background--neutral: var(--ui-9-a30);
  --pill__color--neutral: var(--ui-9);
  --pill__background--selected: var(--green-01);
  --pill__color--selected: var(--grey-03);
  --pill__background--default: var(--ui-9-a30);
  --pill__color--default: var(--ui-0);

  // Icon stats
  --icon-stat__label-color: var(--ui-9);

  // Forms
  --form-field__background: var(--ui-3);
  --form-field__border: var(--ui-6);
  --form-field__border--focus: var(--highlight-5);
  --form-field__checkbox-background: var(--ui-8);
  --form-field__checkbox-background--selected: var(--highlight-1);
  --form-field__checkbox-tick-color: var(--ui-1);
  --form-field__color: var(--ui-0);
  --form-field__select-color: var(--ui-9);
  --form-field__select-icon-color: var(--ui-9);
  --form-field__placeholder: var(--ui-9);
  --form-field__background--disabled: var(--ui-1);
  --form-field--code__background: var(--ui-4);
  --form-field--code__border: var(--ui-9);
  --form-field--code__color: var(--ui-0);
  --form-field--code__placeholder: var(--ui-9);
  --form-field--code-entered-text: var(--highlight-1);
  --form-field__border-radius: 0.8rem;
  --form__preicon__color: var(--ui-9);
  --form__preicon__color--valid: var(--highlight-1);

  // Map
  --popup__background: var(--ui-1);
  --popup__border: var(--ui-6);
  --popup__country-text: var(--ui-9);
  --map-controls__background: var(--ui-3);
  --map-country--major: var(--ui-0);
  --map-country--high: var(--ui-9);
  --map-country--medium: var(--grey-015);
  --map-country--low: var(--ui-8);

  // Charts
  --chart__text: var(--ui-9);
  --chart__gridlines: var(--ui-3);

  --tooltip__background: var(--ui-1);
  --tooltip__border: var(--ui-6);
  // Bandwidth chart
  --bandwidth-chart__gridlines: var(--chart__gridlines);
  --bandwidth-chart__ticks: var(--chart__gridlines);
  --bandwidth-chart__labels: var(--chart__text);
  --bandwidth-chart__legend: var(--chart__text);
  --bandwidth-chart__in-colour: var(--highlight-1);
  --bandwidth-chart__in-colour--hover: var(--highlight-1-a30);
  --bandwidth-chart__in95-colour: var(--highlight-3);
  --bandwidth-chart__out-colour: var(--highlight-5);
  --bandwidth-chart__out-colour--hover: var(--highlight-5-a30);
  --bandwidth-chart__out95-colour: var(--highlight-8);
  // Donut chart (single set)
  --donut-chart__background--0: var(--highlight-3);
  --donut-chart__background--1: var(--highlight-8);
  --donut-chart__background--2: rgb(19, 114, 164);
  --donut-chart__background--3: rgb(24, 210, 160);
  --donut-chart__background--4: rgb(115, 160, 130);
  --donut-chart__background--5: rgb(109, 124, 159);
  --donut-chart__background--6: rgb(20, 168, 149);
  --donut-chart__background--7: rgb(19, 141, 164);
  --donut-chart__background--8: rgb(176, 183, 94);
  --donut-chart__background--9: rgb(162, 125, 82);
  --donut-chart__background--0-hover: var(--highlight-3-a30);
  --donut-chart__background--1-hover: rgb(42, 198, 208, 0.3);
  --donut-chart__background--2-hover: rgb(19, 114, 164, 0.3);
  --donut-chart__background--3-hover: rgb(24, 210, 160, 0.3);
  --donut-chart__background--4-hover: rgb(115, 160, 130, 0.3);
  --donut-chart__background--5-hover: rgb(109, 124, 159, 0.3);
  --donut-chart__background--6-hover: rgb(20, 168, 149, 0.3);
  --donut-chart__background--7-hover: rgb(19, 141, 164, 0.3);
  --donut-chart__background--8-hover: rgb(176, 183, 94, 0.3);
  --donut-chart__background--9-hover: rgb(162, 125, 82, 0.3);
  --donut-chart__border: var(--ui-1);
  // Donut chart (multiset)
  --donut-chart__background--0-0: var(--highlight-3);
  --donut-chart__background--0-1: var(--highlight-3-a30);
  --donut-chart__background--1-0: var(--highlight-1);
  --donut-chart__background--1-1: var(--highlight-1-a30);
  --donut-chart__background--2-0: var(--highlight-5);
  --donut-chart__background--2-1: var(--highlight-5-a30);
  --donut-chart__background--3-0: var(--highlight-1);
  --donut-chart__background--3-1: var(--highlight-1-a30);
  --donut-chart__background--0-0-hover: var(--highlight-3-a60);
  --donut-chart__background--0-1-hover: var(--highlight-3-a30);
  --donut-chart__background--1-0-hover: var(--highlight-1-a60);
  --donut-chart__background--1-1-hover: var(--highlight-1-a30);
  --donut-chart__background--2-0-hover: var(--highlight-5-a60);
  --donut-chart__background--2-1-hover: var(--highlight-5-a30);
  --donut-chart__background--3-0-hover: var(--highlight-1-a60);
  --donut-chart__background--3-1-hover: var(--highlight-1-a30);
  --donut-chart__track-0: var(--highlight-3-a20);
  --donut-chart__track-1: var(--highlight-1-a20);
  --donut-chart__track-2: var(--highlight-5-a20);
  --donut-chart__track-3: var(--highlight-1-a20);
  --donut-chart__border: var(--ui-1);
  --donut-chart__skeleton: var(--ui-0);
  // Line chart
  --line-chart--blocked-border: var(--highlight-4);
  --line-chart--blocked-background: var(--highlight-4-a30);
  --line-chart--allowed-border: var(--highlight-1);
  --line-chart--allowed-background: var(--highlight-1-a30);
  --line-chart--cursor-color: var(--ui-0);
  // Legend
  --legend__label-color: var(--ui-0);
  --legend__value-color: var(--ui-0);

  // Icon titles
  --icon-title__color: var(--highlight-5);

  // Datepicker
  --datepicker-field__color: var(--ui-9);
  --datepicker-cal__background: var(--ui-1);
  --datepicker-cal__background--disabled: var(--ui-4);
  --datepicker-cal__color--disabled: var(--ui-9);
  --datepicker-cal__day: var(--ui-0);
  --datepicker-cal__day--inactive: var(--ui-9);
  --datepicker-cal__day--active: var(--ui-1);
  --datepicker-cal__day__background--active: var(--highlight-1);
  --datepicker-cal__day--in-range: var(--ui-1);
  --datepicker-cal__day__background--in-range: var(--highlight-1-a30);
  --datepicker-cal__month: var(--card__color);
  --datepicker-cal__year: var(--ui-9);
  --datepicker-cal__arrow: var(--ui-9);
  --datepicker-cal__weekday: var(--ui-9);
  --datepicker-cal__border: var(--ui-6);
  --datepicker-cal__shadow: var(--ui-1);
  --datepicker-cal__hover: var(--highlight-1);

  // Contact cards
  --contact-card__title: var(--ui-0);
  --contact-card__position: var(--ui-0);
  --contact__icon: var(--highlight-5);
  --contact__hours: var(--ui-0);

  // Toasts
  --toast--info-background: var(--highlight-3);
  --toast--error-background: var(--highlight-4);
  --toast--success-background: var(--highlight-1);
  --toast--warning-background: var(--highlight-2);
  --toast__icon-background: var(--ui-0);
  --toast__text-color: var(--ui-0);
  --cookie-consent__background: var(--ui-6);
  --cookie-consent__colour: var(--ui-0);

  // Datatables
  --datatable__background-color: var(--ui-3);
  --datatable__row-border-color: var(--ui-6);
  --datatable__header-color: var(--ui-0);
  --datatable__cell-color: var(
    --ui-9
  ); // Default cell colour is grey - use cell classes to change them
  --datatable__sort-arrow: var(--ui-0);
  --datatable__row-background-color: var(--ui-4);
  --datatable__footer-stats-color: var(--ui-9);

  --datatable__row-hover: var(--ui-7);

  // Pagination
  --pagination__item-color: var(--ui-0);
  --pagination__item-icon-color: var(--ui-9);
  --pagination__item-background: var(--ui-6);
  --pagination__item-background-hover: var(--ui-1-a30);
  --pagination__item--current-color: var(--ui-1);
  --pagination__item--current-background: var(--highlight-1);
  --pagination__item--current-background-hover: var(--highlight-1-a30);

  // Filter toggles
  --form-toggle__label-color: var(--ui-9);
  --form-toggle__label-color--active: var(--highlight-1);
  --form-toggle__label-border--active: var(--highlight-1);

  // Country
  --country-online: var(--ui-8);

  // Network diagram
  --network-diagram__background: var(--ui-4);
  --network-diagram__grid: var(--ui-7);
  --network-label__background: var(--ui-1);
  --network-label__border: var(--ui-6);
  --network-blob__background: var(--ui-4);
  --network-blob__colour--up: var(--highlight-1);
  --network-blob__colour--down: var(--highlight-4);
  --network-blob__colour--failover: var(--highlight-2);
  --network-blob__colour--inactive: var(--highlight-5);

  --network-panel__label: var(--highlight-5);
  --network-panel__border: var(--ui-6);
  --network-panel__color--success: var(--highlight-1);
  --network-panel__color--warning: var(--highlight-2);
  --network-panel__color--error: var(--highlight-4);
  --network-panel__color--idle: var(--ui-8);

  // Modals
  --modal-background: var(--ui-1-a60);
  --modal-color: var(--ui-0);
  --modal__header-background: var(--highlight-1);
  --modal__header-color: var(--ui-1);
  --modal__close-color: var(--ui-1);
  --modal__close-hover-color: var(--ui-1-a30);

  // Account Summary
  --account-summary-bg: var(--ui-6);
  --account-summary--has-details: var(--ui-3);
  --account-summary_service: var(--ui-3);

  // Profile
  --profile-text: var(--ui-0);
  --profile-label: var(--grey-01);
  --profile__line-color: var(--ui-6);
  --profile__mfa-status-color: var(--ui-0);

  // Comments
  --comment-background: var(--card__background);
  --comment__header: var(--ui-6);
  --comment__content: var(---ui-9);

  // Firewall Rules
  --firewall-rules-color: var(--ui-9);
  --firewall-rules-background-color: var(--ui-7);
  --firewall-rules-title-color: var(--ui-0);
  --firewall-rules__details-border: var(--ui-6);

  // Raise tickets
  --raise-ticket__text: var(--ui-0);

  // Stacked bar chart
  --stacked-bar__1: var(--highlight-1);
  --stacked-bar__2: var(--highlight-9);
  --stacked-bar__3: var(--ui-7);
  --stacked-bar-diagram--title: var(--ui-9);

  // Options button
  --options-button__options-background: var(--ui-6);
  --options-button__option-border: var(--ui-6);
  --options-button__option-background-hover: var(--highlight-1);
  --options-button__option-color-hover: var(--ui-6);

  --info-box-background: var(--ui-6);

  // Secondary Navigation
  --secondary-nav-background: var(--ui-3);
  --secondary-nav-border: var(--ui-5);
  --secondary-nav-textbox: var(--highlight-5);
  --secondary-nav-textbox__highlight: var(--ui-0);
  --active-tab-color: var(--highlight-1);

  // News stories
  --news-story__date-color: var(--ui-9);
  --news-story__image-container__background: var(--ui-6);
  --news-story__date--skeleton__background: var(--ui-9);
  --news-story__title--skeleton__background: var(--ui-9);

  // Contact support widget
  --contact-text--offline: var(--ui-9);
  --contact-status-indicator--online: var(--highlight-1);
  --contact-status-indicator--offline: var(--highlight-4);
  --contact-border: var(--ui-6);

  // Connection status (IPSEC VPNs)
  --connection-status-text--active: var(--success);
  --connection-status-text--inactive: var(--error);

  // VPN User Summary
  --user-summary__title-color: var(--ui-0);
  --user-summary__subtitle-color: var(--ui-9);

  // Password strength
  --password-strength__background: var(--ui-3);
  --password-strength__color: var(--ui-0);
  --password-strength__check__color: var(--highlight-1);
  --password-strength__cross__color: var(--highlight-4);

  // Carousel
  --carousel__background: var(--ui-6);
  --carousel__card-text__color: var(--ui-9);
  --carousel__pip__background: var(--ui-7);
  --carousel__pip__background--active: var(--highlight-1);

  // 2FA
  --two-factor-auth__verification-footer__color: var(--ui-9);
  --two-factor-auth__verification-footer__link-color: var(--highlight-1);
  --two-factor-auth__loading-color: var(--highlight-1);

  // Protect
  --threat-summary__separator__color: var(--ui-6);

  // Firewall rules
  --firewall-sub-text-color: var(--ui-9);
  --firewall-webfilter__detail__name-color: var(--ui-9);

  // Loading
  --loading__colour: var(--ui-9);
  --loading__background: var(--ui-1-a60);

  // Panels
  --panel__title__color: var(--highlight-1);
  --panel__subtitle__color: var(--ui-9);

  // Auth container
  --auth-container__title__color: var(--ui-0);
  --auth-container__footer__color: var(--ui-9);
  --auth-container__header-bar__color: var(--highlight-1);
  --auth-container__header-bar__gradient: linear-gradient(
    90deg,
    var(--highlight-1) 0,
    var(--highlight-1) 10%,
    var(--highlight-9) 40%,
    var(--highlight-9) 60%,
    var(--highlight-1) 90%,
    var(--highlight-1) 100%
  );

  // Single component definitions :(
  --footer__version-number: var(--ui-8);
  --main-content__background: var(--ui-2);
  --service-request-choice__border: var(--ui-6);
  --version-number__color: var(--ui-9);
  --text-decoration__color: var(--ui-0);
  --four-oh-four__color: var(--ui-0);
  --arrow-list__arrow: var(--ui-9);
  --login__form-details__color: var(--ui-9);

  // Change VPN Password
  --change-vpn-passwords__action__color: var(--highlight-1);
  --change-vpn-passwords__action__color--inactive: var(--ui-8);
  --change-vpn-passwords__cross__color: var(--highlight-4);

  // Ticket
  --ticket__color--success: var(--ui-2);
}
