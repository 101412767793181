@use "mixins";
@use "typography" as *;

.inner-card {
  background-color: var(--inner-card__background);
  border-radius: 0.8rem;
  margin-bottom: 2rem;
  padding: 2rem 0;
  overflow: hidden;

  &__title {
    @include mixins.widgetTitle;
    font-family: var(--font-family-heading);
    font-size: $font-size--xl;
    font-weight: $font-weight-heading--medium;
    margin-bottom: 1.4rem;
    background-color: var(--inner-card__title__background);
    padding: 0 2.4rem;
  }

  &--no-padding {
    padding: 0;
  }
}
