@use "typography" as *;
@use "mixins" as *;

/**
 * MAPBOX
 *
 * This SCSS cannot be loaded on component level, otherwise Mapbox won't pick it up when rendering the map.
 *
 **/
.mapboxgl-popup .mapboxgl-popup-content {
  @include card;
  display: block;
  text-align: center;
  width: auto;
  max-width: 12rem;
  min-width: 9rem;
  height: auto;
  padding: 1rem 1.4rem;
  overflow: visible;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-content {
  @include arrow("bottom");
}
.mapboxgl-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-content {
  @include arrow("top");
}
.mapboxgl-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-content {
  @include arrow("left");
}
.mapboxgl-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-content {
  @include arrow("right");
}

.mapboxgl-popup .mapboxgl-popup-content img {
  width: 2.4rem;
  height: 2.4rem;
}

.mapboxgl-popup .mapboxgl-popup-content .amount {
  font-weight: $font-weight-body--semibold;
  font-family: var(--font-family-body);
  font-size: $font-size--sm;
  text-transform: none;
  margin-bottom: 0.3rem;
}

.mapboxgl-popup .mapboxgl-popup-content .country-name {
  color: var(--popup__country-text);
  line-height: 1.3rem;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border: none;
}
.mapboxgl-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border: none;
}
.mapboxgl-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border: none;
}
.mapboxgl-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border: none;
}

// Double classed for specificity
.mapboxgl-ctrl-bottom-left.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-left.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right.mapboxgl-ctrl-top-right {
  z-index: 1;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-group button {
  background-color: var(--map-controls__background);
  border: none;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group
  button.mapboxgl-ctrl-zoom-out
  .mapboxgl-ctrl-icon {
  background-image: url("/assets/img/icons/map-zoom-out.svg");
}

.mapboxgl-ctrl.mapboxgl-ctrl-group
  button.mapboxgl-ctrl-zoom-in
  .mapboxgl-ctrl-icon {
  background-image: url("/assets/img/icons/map-zoom-in.svg");
}

.mapboxgl-map {
  position: inherit;
}
